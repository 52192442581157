import React, { useState } from 'react';
import { navigate } from 'gatsby';
import { useRecoilValue } from 'recoil';

import { Page } from '../../../../components/shared/Page';
import { getOpenApplication } from '../../../../state/personalInfo';
// import { KontomaticUpload } from './KontomaticUpload';
import { ManualUpload } from './ManualUpload';
// import { ButtonGroup } from '../../../shared/ButtonGroup';
import api from '../../../../utilities/api';
import { ConsentModal } from './ConsentModal';
import { useApplications } from '../../../../hooks/useApplications';

// import { useGTMEvent } from '../../../../hooks/useGTMEvent';

export const BankStatements = () => {
  const { id } = useRecoilValue(getOpenApplication);
  // const [showKontomatik, setShowKontomatik] = useState(false);
  const [showConsentModal, setShowConsentModal] = useState(false);
  const { mutate } = useApplications();

  // const automaticGTMEvent = useGTMEvent({
  //   event: 'dashboard:upload:bank:statement:auto',
  //   eventCategory: 'dashboard',
  //   eventAction: 'upload',
  //   eventLabel: 'dashboard_upload_bank_statement_auto',
  // });
  // const manualGTMEvent = useGTMEvent({
  //   event: 'dashboard:upload:bank:statement:manually',
  //   eventCategory: 'dashboard',
  //   eventAction: 'upload',
  //   eventLabel: 'dashboard_upload_bank_statement_manually',
  // });

  const finish = async () => {
    await api.closeApplication(id);
    await mutate();
    navigate('/moje/nastenka');
  };

  // const handleAutomaticStatements = () => {
  //   automaticGTMEvent();
  //   setShowKontomatik(true);
  // };
  // const handleManualStatements = () => {
  //   manualGTMEvent();
  //   setShowKontomatik(false);
  // };

  return (
    <Page title="Nahrajte výpisy z&nbsp;bankovního účtu">
      {showConsentModal && (
        <ConsentModal setShowConsentModal={setShowConsentModal} />
      )}
      {/* <ButtonGroup
        leftButtonLabel="Automatické nahrání"
        rightButtonLabel="Manuálné nahrání"
        onLeftButtonClicked={handleAutomaticStatements}
        onRightButtonClicked={handleManualStatements}
        className="text-center mb-8"
      /> */}
      {/* {showKontomatik && <KontomaticUpload id={id} finish={finish} />}
      {!showKontomatik && <ManualUpload id={id} finish={finish} />} */}

      <ManualUpload id={id} finish={finish} />
    </Page>
  );
};
