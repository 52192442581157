import React from 'react';
import PropTypes from 'prop-types';
import { ProgressBarStep } from './ProgressBarStep';

export const ProgressBar = ({ steps }) => {
  return (
    <section className="pt-32 flex flex-col items-center">
      {steps.map((step) => (
        <ProgressBarStep key={step.key} {...step} />
      ))}
    </section>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.object).isRequired,
};
