import React, { Fragment, useContext, useEffect, Suspense } from 'react';
import { navigate } from 'gatsby';

import { AuthContext } from '../../../contexts/AuthContext';
import { Layout } from '../../../components/shared/Layout';
import { Seo } from '../../../components/shared/Seo';
import { Spinner } from '../../../components/shared/Spinner';
import { Insolvence } from './cards/Insolvence';
import { useVocative } from '../../../hooks/useVocative';
import { useApplicationScore } from '../../../hooks/useApplicationScore';
import { useApplications } from '../../../hooks/useApplications';
import { ScoreHintCard } from './cards/ScoreHintCard';
import { ScoreCard } from './cards/ScoreCard';
// import { Distraint } from './cards/Distraint';

const DashboardContent = () => {
  const { user, loadAndSetUser } = useContext(AuthContext);
  const { firstName } = user;
  const { vocative } = useVocative(firstName);
  const { result, recommendations, scoringDateFinished } =
    useApplicationScore();
  const { isLoading, isValidating, applications } = useApplications();

  useEffect(() => {
    if (result) {
      loadAndSetUser();
    }
  }, [result, loadAndSetUser]);

  useEffect(() => {
    if (
      !isLoading &&
      !isValidating &&
      applications &&
      applications !== null &&
      applications.length === 0
    ) {
      navigate('/moje/skore');
    }
  }, [isLoading, isValidating, applications]);

  const score = result?.finalScore;
  const scorePercentile = result?.finalScorePercentile;
  const estimatedInterestRate = result?.estimatedInterestRate;
  const redirectHash = result?.redirectHash;
  const isScored = !!score;

  return (
    <Layout theme="in-app">
      <Seo title="Nástěnka" suppressRobots={true} />
      <section className="max-w-screen-xl mx-auto lg:flex flex-col">
        <div className="flex flex-col">
          <h1
            className="mb-4 text-4xl font-normal tracking-wide"
            data-testid="dashboard-title"
          >
            Dobrý den, <span className="font-bold">{vocative}</span>,
          </h1>
          <p className="text-lg" data-testid="dashboard-subtitle">
            {isScored && (
              <Fragment>
                tady je výsledek, který jsme spočítali na základě toho, co od
                vás víme.
              </Fragment>
            )}
            {!isScored && (
              <Fragment>
                vypadá to, že chcete zjistit své osobní skóre, ale během
                vyplňování vás něco vyrušilo.
              </Fragment>
            )}
          </p>
        </div>

        <div className="mt-9">
          <ScoreCard
            score={score}
            scorePercentile={scorePercentile}
            scoreDate={scoringDateFinished}
            estimatedInterestRate={estimatedInterestRate}
            redirectHash={redirectHash}
          />
        </div>

        <div className="flex flex-col mt-8 lg:flex-row space-y-8 lg:space-y-0">
          {recommendations?.length > 0 && (
            <div className="flex flex-col space-y-6 lg:w-4/6 lg:mr-6">
              <ScoreHintCard recommendations={recommendations} />
            </div>
          )}

          <aside className="flex-col space-y-6 lg:w-2/6">
            <Suspense fallback={<Spinner size={64} />}>
              <Insolvence />
            </Suspense>
          </aside>
        </div>
      </section>
    </Layout>
  );
};

export const Dashboard = () => (
  <Suspense
    fallback={
      <div className="flex min-h-screen items-center justify-center">
        <Spinner size={64} className="mx-auto" />
      </div>
    }
  >
    <DashboardContent />
  </Suspense>
);
