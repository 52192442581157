import React from 'react';
import PropTypes from 'prop-types';

import Stack1 from '../../../../../../images/svg/stack-1.svg';
import Bars from '../../../../../../images/svg/bars.svg';
import Stack2 from '../../../../../../images/svg/stack-2.svg';

export const Recommendations = ({ recommendations }) => {
  const sorted = recommendations
    .slice(0)
    .sort((a, b) => a.priority - b.priority);
  return (
    <div
      className="relative grid -mt-6 md:mt-4 md:grid-cols-3 gap-y-4 md:gap-x-9"
      data-testid="recommendations"
    >
      <Stack1 className="justify-self-center md:mt-0 md:order-0" />
      <h4
        className="text-center font-bold text-xl leading-5 md:order-4"
        data-testid="recommendations-item-title"
      >
        {sorted[0].title}
      </h4>
      <p
        className="text-center md:order-7"
        data-testid="recommendations-item-message"
      >
        {sorted[0].message}
      </p>

      <Bars className="mt-6 md:mt-0 md:order-2 justify-self-center" />
      <h4
        className="text-center font-bold text-xl leading-5 md:order-5"
        data-testid="recommendations-item-title"
      >
        {sorted[1].title}
      </h4>
      <p
        className="text-center md:order-8"
        data-testid="recommendations-item-message"
      >
        {sorted[1].message}
      </p>

      <Stack2 className="mt-6 md:mt-0 md:order-3 justify-self-center" />
      <h4
        className="text-center font-bold text-xl leading-5 md:order-6"
        data-testid="recommendations-item-title"
      >
        {sorted[2].title}
      </h4>
      <p
        className="text-center md:order-9"
        data-testid="recommendations-item-message"
      >
        {sorted[2].message}
      </p>
    </div>
  );
};

const recommendation = PropTypes.shape({
  title: PropTypes.string,
  message: PropTypes.string,
  priority: PropTypes.number,
});

Recommendations.propTypes = {
  recommendations: PropTypes.arrayOf(recommendation),
};
