import { useState, useEffect } from 'react';
import useSWR from 'swr';

const swrOptions = {
  suspense: true,
  revalidateOnFocus: false,
};

export const useApplications = () => {
  const [applications, setApplications] = useState(null);

  const {
    mutate,
    data: allApplications,
    isLoading,
    isValidating,
  } = useSWR('/api/users/me/applications', swrOptions);

  useEffect(() => {
    if (allApplications) {
      setApplications(allApplications);
    }
  }, [allApplications]);

  return { applications, isLoading, isValidating, mutate };
};
