import React, { useEffect, useState, useContext } from 'react';
import useSWR from 'swr';
import format from 'date-fns/format';
import { cs } from 'date-fns/locale';
import { useQueryParam, StringParam } from 'use-query-params';
import scrollTo from 'gatsby-plugin-smoothscroll';

import CheckBare from '../../../../../images/svg/check-bare.svg';
import Clock from '../../../../../images/svg/clock.svg';
import arrowRight from '../../../../../images/icons/arrow-right.svg';

import { AuthContext } from '../../../../../contexts/AuthContext';
import { ErrorContext } from '../../../../../contexts/ErrorContext';
import { Card } from '../../../../../components/shared/Card';
import { Button } from '../../../../../components/shared/Button';
import { ButtonContentWithIcon } from '../../../../../components/shared/ButtonContentWithIcon';
import api from '../../../../../utilities/api';
import { humanizePersonalNo } from '../../../../../utilities/humanizePersonalNo';
import { useGTMEvent } from '../../../../../hooks/useGTMEvent';

const mapping = {
  'MYLNY ZAP': 'Mylný zápis',
  NEVYRIZENA: 'Nevyřízená věc',
  OBZIVLA: 'Obživlá věc',
  ODSKRTNUTA: 'Odškrtnutá',
  VYRIZENA: 'Vyřízená věc',
  PRAVOMOCNA: 'Pravomocná věc',
  KONKURS: 'Konkurs',
  'ZRUŠENO VS': 'Zrušeno vrchním soudem',
  'K-PO ZRUŠ.': 'Konkurs po zrušení',
  ÚPADEK: 'V úpadku',
  REORGANIZ: 'Reorganizace',
  ODDLUŽENÍ: 'Oddlužení',
  MORATORIUM: 'Moratorium',
  'NEVYR-POST': 'Postoupená věc',
};

const ISIR_NO_RECORD_ERROR_CODE = 'WS_2';

const InsolvencePersonalNumberAbsent = () => {
  const verifyGTMEvent = useGTMEvent({
    event: 'dashboard:insolvency:register',
    eventCategory: 'dashboard',
    eventAction: 'button',
    eventLabel: 'dashboard_insolvency_register',
  });

  return (
    <Card
      theme="transparent"
      header={
        <span data-testid="insolvence-title" id="insolvence">
          Insolvenční rejstřík
        </span>
      }
    >
      <p data-testid="insolvence-text">
        Potřebujete ověřit, zda nejste v insolvenci? Umožníme vám nahlédnout do
        insolvenčního rejstříku.
      </p>

      <div className="mt-4" data-testid="insolvence-link">
        <Button
          onClick={verifyGTMEvent}
          type="button"
          to="/moje/insolvence"
          color="secondary"
          border="thin"
          className="my-6 uppercase"
          data-testid="dashboard-start-insolvence"
        >
          Zkontrolovat
        </Button>
      </div>
    </Card>
  );
};

const InsolvenceEmptyRegister = ({ onClick, timeCreated }) => {
  const { user } = useContext(AuthContext);

  const { personalNo } = user;
  return (
    <Card
      header={
        <div className="flex flex-col md:flex-row md:justify-between lg:flex-col">
          <span data-testid="insolvence-title" id="insolvence">
            Insolvenční rejstřík
          </span>
          <div
            className="mt-6 md:mt-0 flex items-center text-grey-5 text-sm font-normal lg:mt-6"
            data-testid="insolvence-timestamp"
          >
            <CheckBare className="inline-block mr-2 w-4" />
            {format(new Date(timeCreated), 'd. M. yyyy', {
              locale: cs,
            })}
          </div>
        </div>
      }
    >
      <p className="flex items-center text-lg" data-testid="insolvence-text">
        K rodnému číslu {humanizePersonalNo(personalNo)} nebyl nalezen žádný
        záznam.
      </p>

      <RefreshButton onClick={onClick} />
    </Card>
  );
};

const RefreshButton = ({ onClick }) => {
  const [isReloading, setIsReloading] = useState(false);
  const againGTMEvent = useGTMEvent({
    event: 'dashboard:insolvency:register:again',
    eventCategory: 'dashboard',
    eventAction: 'button',
    eventLabel: 'dashboard_insolvency_register_again',
  });

  const handleClick = async () => {
    againGTMEvent();

    setIsReloading(true);
    await onClick();
    setIsReloading(false);
  };

  return (
    <Button
      type="button"
      onClick={handleClick}
      color="secondary"
      border="thin"
      disabled={isReloading}
      className="my-6 uppercase"
      data-testid="dashboard-start-insolvence"
    >
      Zkontrolovat
    </Button>
  );
};

export const Insolvence = () => {
  const { user } = useContext(AuthContext);
  const { setGlobalError } = useContext(ErrorContext);
  const [scrollToParam] = useQueryParam('scrollTo', StringParam);

  const { personalNo } = user;

  const { data, mutate } = useSWR(personalNo ? `/api/isir-register` : null, {
    suspense: true,
    revalidateOnFocus: false,
  });

  useEffect(() => {
    if (scrollToParam) {
      scrollTo(`#${scrollToParam}`);
    }
  }, [scrollToParam]);

  const refresh = async () => {
    try {
      const response = await api.loadInsolvence();
      await mutate(response, false);
    } catch (error) {
      setGlobalError(error);
    }
  };

  if (!personalNo) {
    return <InsolvencePersonalNumberAbsent />;
  }

  if (data.status === 204) {
    return (
      <InsolvenceEmptyRegister onClick={refresh} timeCreated={new Date()} />
    );
  }

  const {
    stav: { pocetVysledku, kodChyby },
    data: dataArray,
    timeCreated,
  } = data;

  if (
    kodChyby === ISIR_NO_RECORD_ERROR_CODE ||
    pocetVysledku === 0 ||
    pocetVysledku === null
  ) {
    return (
      <InsolvenceEmptyRegister onClick={refresh} timeCreated={timeCreated} />
    );
  }

  return (
    <Card
      header={
        <div className="flex flex-col md:flex-row md:justify-between lg:flex-col">
          <span data-testid="insolvence-title" id="insolvence">
            Insolvenční rejstřík
          </span>
          <div
            className="mt-6 md:mt-0 flex items-center text-grey-5 text-sm font-normal lg:mt-6"
            data-testid="insolvence-timestamp"
          >
            <Clock className="inline-block mr-2 w-4 fill-grey-2" />
            {format(new Date(timeCreated), 'd. M. yyyy', {
              locale: cs,
            })}
          </div>
        </div>
      }
    >
      <div className="flex flex-wrap gap-6" data-testid="insolvence-entries">
        {dataArray.map(
          ({
            bcVec,
            cisloSenatu,
            druhVec,
            rocnik,
            rc,
            druhStavKonkursu,
            ulice,
            cisloPopisne,
            mesto,
            psc,
          }) => (
            <dl
              key={bcVec}
              className="py-3 px-4 ring-1 ring-black/10 rounded-md bg-black/2"
            >
              <dt className="uppercase font-bold text-lg">Stav</dt>
              <dd data-testid="insolvence-table-type">
                {mapping[druhStavKonkursu] || druhStavKonkursu}
              </dd>

              <dt className="mt-2 uppercase font-bold text-lg">
                Spisová značka
              </dt>
              <dd data-testid="insolvence-table-identification">
                {cisloSenatu} {druhVec} {bcVec}/{rocnik}
              </dd>

              <dt className="mt-2 uppercase font-bold text-lg">Rodné číslo</dt>
              <dd data-testid="insolvence-table-personal-no">{rc}</dd>

              <dt className="mt-2 uppercase font-bold text-lg">Bydlište</dt>
              <dd data-testid="insolvence-table-address">
                {ulice} {cisloPopisne}, {mesto} {psc}
              </dd>

              <dt className="mt-2 uppercase font-bold text-lg">
                Výsledek lustrace
              </dt>
              <dd data-testid="insolvence-table-isir-link">
                <ButtonContentWithIcon
                  url={`https://isir.justice.cz/isir/ueu/vysledek_lustrace.do?rc=${rc.replace(
                    '/',
                    ''
                  )}&aktualnost=AKTUALNI_I_UKONCENA`}
                  size="small"
                  className="mt-1"
                  iconSrc={arrowRight}
                  data-testid="insolvence-table-isir-link"
                />
              </dd>
            </dl>
          )
        )}
      </div>

      <RefreshButton onClick={refresh} />
    </Card>
  );
};
