import React from 'react';
import PropTypes from 'prop-types';

import { RadioGroup } from '../../RadioGroup';
import { FormGroupError } from '../FormGroupError';

export const FormSex = ({ onChange, onBlur, checked }) => {
  return (
    <div className="border-grey-1 border-b pb-8">
      <span className="text-xl">Jste</span>
      <RadioGroup
        options={[
          { value: 'MALE', label: 'Muž' },
          { value: 'FEMALE', label: 'Žena' },
        ]}
        name="gender"
        handleChange={onChange}
        handleBlur={onBlur}
        test={{
          'data-testid': 'soc-dem-gender-radio-buttons',
        }}
        checked={checked}
      />
      <FormGroupError name="gender" />
    </div>
  );
};

FormSex.propTypes = {
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  checked: PropTypes.string,
};
