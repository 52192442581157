import React, { useState } from 'react';
import { navigate } from 'gatsby';

import { Modal } from '../../shared/Modal';
import { ModalTitle } from '../../shared/Modal/ModalTitle';
import { Button } from '../../shared/Button';

export const OutOfDateResultModal = ({ state }) => {
  const [showOutOfDateResultModal, setShowOutOfDateResultModal] = useState(
    [
      'SCORED_GOOD_RESULT_OUT_OF_DATE',
      'SCORED_BAD_RESULT_OUT_OF_DATE',
    ].includes(state)
  );

  const startOnboarding = () => {
    navigate('/moje/skore/');
  };

  if (showOutOfDateResultModal) {
    return (
      <Modal
        onClose={() => {
          setShowOutOfDateResultModal(false);
        }}
        data-testid="out-of-date-result-modal"
      >
        <ModalTitle>Váš výsledek už nemusí být aktuální...</ModalTitle>
        <div className="pb-2 text-base leading-6">
          <p className="text-center">
            Doporučujeme vám spočítat si skóre znovu.
          </p>
        </div>
        <div className="flex flex-col justify-center gap-y-3 max-w-96 mx-auto">
          <Button
            onClick={() => {
              startOnboarding();
            }}
            className="w-full"
            data-testid="out-of-date-result-modal/button-recalculate-score"
          >
            spočítat skóre znovu
          </Button>
          <Button
            onClick={() => {
              setShowOutOfDateResultModal(false);
            }}
            color="secondary"
            border="thin"
            className="w-full"
            data-testid="out-of-date-result-modal/button-close"
          >
            zavřít
          </Button>
        </div>
      </Modal>
    );
  }

  return null;
};
