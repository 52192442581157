import React from 'react';
import PropTypes from 'prop-types';

import { FormGroupError } from '../FormGroupError';
import { IconSelect } from '../../IconSelect';
import IconsMaps from '../../../../IconsMaps';

const housingTypeOptions = {
  name: 'Forma bydlení',
  registerItems: [
    {
      code: '2',
      name: 'Ve vlastním s hypotékou',
      status: 'ACTIVE',
    },
    {
      code: '1',
      name: 'V nájmu',
      status: 'ACTIVE',
    },
    {
      code: '6',
      name: 'U partnera / partnerky',
      status: 'ACTIVE',
    },
    {
      code: '4',
      name: 'V družstevním bytě',
      status: 'ACTIVE',
    },
    {
      code: '5',
      name: 'U rodiny',
      status: 'ACTIVE',
    },
    {
      code: '3',
      name: 'Ve vlastním bez hypotéky',
      status: 'ACTIVE',
    },
  ],
};

export const FormHousingType = ({
  errors,
  touched,
  onChange,
  onBlur,
  selectedHousingType,
}) => {
  return (
    <div>
      <span className="text-xl mb-8 block">Typ bydlení</span>
      {housingTypeOptions && (
        <IconSelect
          name="housingType"
          options={housingTypeOptions.registerItems}
          errors={errors}
          value={selectedHousingType}
          handleChange={onChange}
          handleBlur={onBlur}
          touched={touched}
          iconsMap={IconsMaps.housingType}
          test={{ 'data-testid': 'housing-type-input' }}
        />
      )}
      <FormGroupError className="text-center pr-8" name="housingType" />
    </div>
  );
};

housingTypeOptions.propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func.isRequired,
  selectedHousingType: PropTypes.string,
};
