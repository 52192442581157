import React from 'react';
import PropTypes from 'prop-types';

import { Modal } from '../../shared/Modal';
import { ModalTitle } from '../../shared/Modal/ModalTitle';
import { Button } from '../../shared/Button';

export const ScorePointsModal = ({
  showScorePointsModal,
  setShowScorePointsModal,
}) => {
  if (showScorePointsModal) {
    return (
      <Modal
        onClose={() => {
          setShowScorePointsModal(false);
        }}
        data-testid="score-points-modal"
      >
        <ModalTitle>Co ovlivňuje váš úrok?</ModalTitle>
        <div
          className="text-base leading-6"
          data-testid="score-points-modal/body"
        >
          <p>
            Čím vyšší skóre máte, tím lepší máte úrok, protože jste pro banky
            důvěryhodnější.
          </p>

          <h3 className="mt-6 mb-1 font-bold">Jak osobní skóre počítáme?</h3>
          <p>
            Na základě bankovních výpisů, které nám poskytnete, vyhodnocujeme
            vaše příjmy a&nbsp;výdaje. Nezkoumáme záznamy v&nbsp;registrech
            dlužníků a&nbsp;insolvenčním rejstříku, proto{' '}
            <span className="font-medium">
              vaše žádost nezanechá v těchto registrech žádný záznam
            </span>
            . Na druhou stranu, při reálné žádosti banky do registrů nahlíží,
            což může váš konečný úrok ještě ovlivnit.
          </p>

          <h3 className="mt-6 mb-1 font-bold">
            Na co se banky v registrech dívají?
          </h3>
          <p className="text-center">
            Včasné splácení vašich závazků zvyšuje vaši důvěryhodnost
            a&nbsp;zlepšuje skóre.
          </p>
          <p className="text-center">
            Časté zamítnutí žádostí o půjčku může být vnímáno negativně.
          </p>
          <p className="text-center">
            Exekuce a insolvence výrazně snižují důvěryhodnost.
          </p>
        </div>
        <Button
          onClick={() => {
            setShowScorePointsModal(false);
          }}
          className="w-64"
          data-testid="score-points-modal-button"
        >
          OK
        </Button>
      </Modal>
    );
  }

  return null;
};

ScorePointsModal.propTypes = {
  showScorePointsModal: PropTypes.bool,
  setShowScorePointsModal: PropTypes.func,
};
