import React, { useState, Fragment } from 'react';
import PropTypes from 'prop-types';
import { navigate } from 'gatsby';
import addDays from 'date-fns/addDays';
import format from 'date-fns/format';
import isAfter from 'date-fns/isAfter';

import { OutOfDateResultModal } from '../../OutOfDateResultModal';

import { formatInterestRate } from '../../../../../utilities/formatNumbers';
import Clock from '../../../../../images/svg/clock.svg';
import CheckBare from '../../../../../images/svg/check-bare.svg';
import Lightbulb from '../../../../../images/svg/lightbulb.svg';
import { Card } from '../../../../../components/shared/Card';
import { Button } from '../../../../shared/Button';
import { Score } from '../../../../shared/Score';
import { ScorePointsModal } from '../../ScorePointsModal';
import { useGTMEvent } from '../../../../../hooks/useGTMEvent';

const SCORE_RESULT_UP_TO_DATE_DAYS_COUNT = 14;

const BlueHeadline = ({ children, className }) => (
  <p
    className={`text-[22px] md:text-3xl font-bold text-secondary ${className}`}
    data-testid="card-main-instructions-title"
  >
    {children}
  </p>
);

const InfoMessage = ({ children, className }) => (
  <p
    className={`px-4 py-3 self-start text-lg font-medium text-secondary bg-blue-light rounded-lg rounded-tl-none ${className}`}
    data-testid="card-main-instructions-subtitle"
  >
    {children}
  </p>
);

export const ScoreCard = ({
  score,
  scorePercentile,
  scoreDate,
  estimatedInterestRate,
  redirectHash,
}) => {
  const [showScorePointsModal, setShowScorePointsModal] = useState(false);
  const pushGTMEvent = useGTMEvent({
    event: 'dashboard:findoutscore',
    eventCategory: 'dashboard',
    eventAction: 'click',
    eventLabel: 'dashboard_findoutscore',
  });

  const isScored = !!score;
  const hasInterestRate = !!estimatedInterestRate;

  let state = null;
  scoreDate = scoreDate ? new Date(scoreDate) : scoreDate;

  if (!isScored) {
    state = 'NOT_SCORED_YET';
  } else if (
    isScored &&
    scoreDate &&
    hasInterestRate &&
    isAfter(new Date(), addDays(scoreDate, SCORE_RESULT_UP_TO_DATE_DAYS_COUNT))
  ) {
    state = 'SCORED_GOOD_RESULT_OUT_OF_DATE';
  } else if (
    isScored &&
    scoreDate &&
    hasInterestRate &&
    !isAfter(new Date(), addDays(scoreDate, SCORE_RESULT_UP_TO_DATE_DAYS_COUNT))
  ) {
    state = 'SCORED_GOOD_RESULT_UP_TO_DATE';
  } else if (
    isScored &&
    scoreDate &&
    !hasInterestRate &&
    isAfter(new Date(), addDays(scoreDate, SCORE_RESULT_UP_TO_DATE_DAYS_COUNT))
  ) {
    state = 'SCORED_BAD_RESULT_OUT_OF_DATE';
  } else if (
    isScored &&
    scoreDate &&
    !hasInterestRate &&
    !isAfter(new Date(), addDays(scoreDate, SCORE_RESULT_UP_TO_DATE_DAYS_COUNT))
  ) {
    state = 'SCORED_BAD_RESULT_UP_TO_DATE';
  } else {
    // do nothing, state is null while several inputs from the hooks are not calculated yet, and it's ok
  }

  const startOnboarding = () => {
    navigate('/moje/skore/');
  };

  let zonkyOnbURL = null;

  if (estimatedInterestRate && redirectHash) {
    zonkyOnbURL = `${process.env.GATSBY_ZONKY_URL}/poptavka?utm_source=${
      process.env.GATSBY_UTM_SOURCE
    }&utm_medium=link&utm_campaign=result&interestRate=${
      estimatedInterestRate / 100
    }&osHash=${redirectHash}`;
  }

  return (
    <Fragment>
      {state && <OutOfDateResultModal state={state} />}
      <ScorePointsModal
        showScorePointsModal={showScorePointsModal}
        setShowScorePointsModal={setShowScorePointsModal}
      />
      <Card
        header={
          <div className="gap-x-10 flex flex-col md:flex-row md:justify-between text-xl md:text-2xl">
            <div data-testid="card-main-title">
              {state === 'NOT_SCORED_YET' && (
                <Fragment>
                  Výpočet skóre <span className="font-normal">čeká na</span>
                  &nbsp;vaše&nbsp;údaje
                </Fragment>
              )}
              {state !== 'NOT_SCORED_YET' && (
                <Fragment>
                  Vaše osobní skóre <span className="font-normal">ze dne</span>{' '}
                  {scoreDate && format(new Date(scoreDate), 'd. M. yyyy')}
                </Fragment>
              )}
            </div>
            <div className="mt-3 md:mt-0 flex flex-wrap items-center md:self-center md:justify-end text-grey-3 md:text-grey-5 text-sm sm:text-base font-normal">
              {state === 'NOT_SCORED_YET' && (
                <Fragment>
                  <Clock className="inline-block mr-2 w-4 fill-orange" />
                  <span>Data k výpočtu chybí.</span>
                </Fragment>
              )}
              {state === 'SCORED_GOOD_RESULT_OUT_OF_DATE' && scoreDate && (
                <Fragment>
                  <Clock className="inline-block mr-2 w-4 fill-orange" />
                  Tento výsledek už&nbsp;
                  <span className="font-medium">nemusí být aktuální</span>.
                </Fragment>
              )}
              {state === 'SCORED_GOOD_RESULT_UP_TO_DATE' && scoreDate && (
                <Fragment>
                  <CheckBare className="inline-block mr-2 w-4" />
                  Tohle skóre bude aktuální zhruba&nbsp;
                  <span className="font-medium">
                    do&nbsp;
                    {format(
                      addDays(
                        new Date(scoreDate),
                        SCORE_RESULT_UP_TO_DATE_DAYS_COUNT
                      ),
                      'd. M. yyyy'
                    )}
                  </span>
                </Fragment>
              )}
              {state === 'SCORED_BAD_RESULT_UP_TO_DATE' && scoreDate && (
                <Fragment>
                  <CheckBare className="inline-block mr-2 w-4" />
                  Tohle skóre bude aktuální zhruba&nbsp;
                  <span className="font-medium">
                    do&nbsp;
                    {format(
                      addDays(
                        new Date(scoreDate),
                        SCORE_RESULT_UP_TO_DATE_DAYS_COUNT
                      ),
                      'd. M. yyyy'
                    )}
                  </span>
                </Fragment>
              )}
              {state === 'SCORED_BAD_RESULT_OUT_OF_DATE' && scoreDate && (
                <Fragment>
                  <Clock className="inline-block mr-2 w-4 fill-orange" />
                  Tohle skóre už&nbsp;
                  <span className="font-medium">nemusí být aktuální</span>.
                </Fragment>
              )}
            </div>
          </div>
        }
      >
        <div className="flex flex-col md:flex-row gap-y-6 sm:gap-x-10">
          {/* LEFT COL START */}
          <div className="mx-auto min-w-72">
            <Score
              score={score}
              estimatedInterestRate={estimatedInterestRate}
              setShowScorePointsModal={setShowScorePointsModal}
            />
          </div>
          {/* LEFT COL END */}

          {/* RIGHT COL START */}
          <div className="text-center md:text-start">
            {state === 'SCORED_GOOD_RESULT_UP_TO_DATE' && (
              <Fragment>
                <BlueHeadline className="mb-3 md:mb-4">
                  Máte lepší výsledek než {formatInterestRate(scorePercentile)}{' '}
                  lidí v&nbsp;Česku.
                </BlueHeadline>

                <InfoMessage className="mb-6 md:mb-4">
                  S takovým výsledkem můžete v klidu přemýšlet o půjčce.
                </InfoMessage>

                <p className="">
                  Jestli půjčku zvažujete,{' '}
                  <span className="font-medium">mrkněte na Zonky</span>.
                </p>
                <p className="mb-3">Můžete dostat zajímavou nabídku.</p>

                <Button
                  onClick={pushGTMEvent}
                  href={zonkyOnbURL}
                  color="primary"
                  target="_blank"
                  border="normal"
                  data-testid="zonky-loan-card-button"
                >
                  Podívat se na nabídku zonky
                </Button>
              </Fragment>
            )}

            {state === 'SCORED_GOOD_RESULT_OUT_OF_DATE' && (
              <Fragment>
                <BlueHeadline className="mb-4 text-2xl">
                  Máte lepší výsledek než {formatInterestRate(scorePercentile)}{' '}
                  lidí v Česku.
                </BlueHeadline>
                <Button
                  onClick={startOnboarding}
                  color="secondary"
                  border="thin"
                  className="mb-10"
                  data-testid="recalculate-score-button"
                >
                  spočítat skóre znovu
                </Button>

                <p className="">
                  Jestli půjčku zvažujete,{' '}
                  <span className="font-medium">mrkněte na Zonky</span>.
                </p>
                <p className="mb-3">Můžete dostat zajímavou nabídku.</p>

                <Button
                  onClick={pushGTMEvent}
                  href={zonkyOnbURL}
                  color="primary"
                  target="_blank"
                  border="normal"
                  data-testid="zonky-loan-card-button"
                >
                  Podívat se na nabídku zonky
                </Button>
              </Fragment>
            )}

            {state === 'NOT_SCORED_YET' && (
              <Fragment>
                <BlueHeadline className="mb-4">
                  Už jen pár kroků a&nbsp;dozvíte se, jak si stojíte.
                </BlueHeadline>
                <InfoMessage className="mb-4">
                  Zbývá pouze vyplnit krátký dotazník a&nbsp;nahrát bankovní
                  výpisy.
                </InfoMessage>

                <Button
                  type="button"
                  onClick={startOnboarding}
                  color="secondary"
                  border="thin"
                  data-testid="dashboard-start-onboarding"
                >
                  Pokračovat ve zjišťování skóre
                </Button>
              </Fragment>
            )}

            {state === 'SCORED_BAD_RESULT_UP_TO_DATE' && (
              <Fragment>
                <BlueHeadline className="mb-4">
                  Tohle skóre by bylo ideální zlepšit.
                </BlueHeadline>
                <InfoMessage className="mb-4">
                  Půjčka by pro vás mohla být zátěží a splácení by se vám
                  nemuselo dařit zvládnout.
                </InfoMessage>
                <div className="mt-14 flex flex-row">
                  <Lightbulb className="inline-block mr-2 w-4" />
                  Podívejte se na tipy, jak si skóre můžete zlepšit. Nejdříve{' '}
                  {format(
                    addDays(
                      new Date(scoreDate),
                      SCORE_RESULT_UP_TO_DATE_DAYS_COUNT
                    ),
                    'd. M. yyyy'
                  )}{' '}
                  si ho můžete spočítat znovu.
                </div>
              </Fragment>
            )}

            {state === 'SCORED_BAD_RESULT_OUT_OF_DATE' && (
              <Fragment>
                <BlueHeadline className="mb-4">
                  Tohle skóre by bylo ideální zlepšit.
                </BlueHeadline>
                <Button
                  onClick={startOnboarding}
                  color="secondary"
                  border="thin"
                  className="mb-10"
                  data-testid="recalculate-score-button"
                >
                  spočítat skóre znovu
                </Button>
                <div className="mt-14 flex flex-row">
                  <Lightbulb className="inline-block mr-2 w-4" />
                  Podívejte se na tipy, jak si skóre můžete zlepšit. Nejdříve{' '}
                  {format(
                    addDays(
                      new Date(scoreDate),
                      SCORE_RESULT_UP_TO_DATE_DAYS_COUNT
                    ),
                    'd. M. yyyy'
                  )}{' '}
                  si ho můžete spočítat znovu.
                </div>
              </Fragment>
            )}
          </div>
          {/* RIGHT COL END */}
        </div>
      </Card>
    </Fragment>
  );
};

BlueHeadline.propTypes = {
  className: PropTypes.string,
  children: PropTypes.any.isRequired,
};

ScoreCard.propTypes = {
  score: PropTypes.number,
  scorePercentile: PropTypes.number,
  scoreDate: PropTypes.string,
  estimatedInterestRate: PropTypes.number,
  redirectHash: PropTypes.string,
};
