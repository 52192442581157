import { useState, useEffect } from 'react';
import useSWR from 'swr';

import { useApplications } from './useApplications';

const swrOptions = {
  suspense: true,
  revalidateOnFocus: false,
};

export const useApplicationScore = () => {
  const [lastApplication, setLastApplication] = useState(null);
  const [scoringDateFinished, setScoringDateFinished] = useState();
  const {
    applications,
    isLoading: isLoadingApplications,
    isValidating: isValidatingApplications,
  } = useApplications();

  const {
    data: score,
    isLoading,
    isValidating,
  } = useSWR(
    lastApplication?.id
      ? `/api/applications/${lastApplication.id}/score`
      : null,
    swrOptions
  );

  useEffect(() => {
    if (
      !isLoadingApplications &&
      !isValidatingApplications &&
      applications !== null
    ) {
      const finishedApps = applications
        .filter(({ status }) => {
          return 'FINISHED' === status;
        })
        .sort(
          (a, b) =>
            new Date(b.scoringDateFinished) - new Date(a.scoringDateFinished)
        );

      const latestFinishedApp = finishedApps[0];

      if (latestFinishedApp) {
        setLastApplication(latestFinishedApp);

        if (score) {
          setScoringDateFinished(latestFinishedApp.scoringDateFinished);
        }
      }
    }
  }, [isLoadingApplications, isValidatingApplications, applications, score]);

  return { ...score, scoringDateFinished, isLoading, isValidating };
};
