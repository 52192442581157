import React, { useContext, useState, Fragment } from 'react';
import useSWR from 'swr';
import { Formik, Form } from 'formik';
import { object } from 'yup';

import { FormGroup } from '../../../shared/form/FormGroup';
import { Spinner } from '../../../shared/Spinner';
import { Button } from '../../../shared/Button';
import Pdf from '../../../../images/svg/pdf.svg';
import api from '../../../../utilities/api';
import { ErrorContext } from '../../../../contexts/ErrorContext';
import { documents } from '../../../../validations';
import { ErrorMessage } from '../../../shared/form/ErrorMessage';
import { useGTMEvent } from '../../../../hooks/useGTMEvent';

const ValidationSchema = object().shape({ data: documents });

export const ManualUpload = ({ id, finish }) => {
  const [error, setError] = useState(null);
  const { setGlobalError } = useContext(ErrorContext);
  const { data, mutate } = useSWR(`/api/applications/${id}/attachments`, {
    suspense: true,
  });

  const uploadGTMEvent = useGTMEvent({
    event: 'dashboard:upload:bank:statement:computer',
    eventCategory: 'dashboard',
    eventAction: 'upload',
    eventLabel: 'dashboard_upload_bank_statement_computer',
  });
  const continueGTMEvent = useGTMEvent({
    event: 'dashboard:upload:bank:statement',
    eventCategory: 'dashboard',
    eventAction: 'upload',
    eventLabel: 'dashboard_upload_bank_statement',
  });

  const initialValues = { data };

  const isInitialValid = ValidationSchema.isValidSync(initialValues);

  const handleUpload = async ({ target }) => {
    const files = Object.values(target.files);
    const attachments = [];

    target.value = null;

    uploadGTMEvent();

    for (const file of files) {
      const formData = new FormData();

      formData.append('attachmentType', 'ACCOUNT_STATEMENT');
      formData.append('data', file);

      try {
        const attachment = await api.createAttachment(id, formData);
        attachments.push(attachment);
      } catch (error) {
        setGlobalError(error);
      }
    }

    await mutate([...data, ...attachments], false);
  };

  const deleteDocument = async (attachmentId) => {
    try {
      const attachments = data.filter(
        (attachment) => attachment.id !== attachmentId
      );

      await api.deleteAttachment(attachmentId);
      await mutate(attachments, false);
    } catch (error) {
      setGlobalError(error);
    }
  };

  const handleFinish = async (_, { setSubmitting }) => {
    try {
      continueGTMEvent();
      await finish();
    } catch (error) {
      if (
        error.isStatementsUnparseable ||
        error.isStatementsEmpty ||
        error.isUnprocessableEntity ||
        error.isBriefHistoryOfStatements ||
        error.isLowNumberOfTransactions ||
        error.isMissingStatementInRow ||
        error.isOldStatements
      ) {
        setSubmitting(false);
        await mutate([], false);

        return setError(error);
      }

      setGlobalError(error);
    }
  };

  return (
    <Fragment>
      <Formik
        initialValues={initialValues}
        validationSchema={ValidationSchema}
        onSubmit={handleFinish}
        isInitialValid={isInitialValid}
        enableReinitialize
      >
        {({ handleSubmit, isSubmitting, isValid }) => (
          <Form className="max-w-4xl mx-auto flex flex-col items-center">
            <div className="p-7 flex flex-col items-center w-full text-xl">
              <ul className="mb-8 list-disc leading-8">
                <li>
                  na který vám <span className="font-bold">chodí příjem</span>
                </li>
                <li>
                  za&nbsp;
                  <span className="font-bold">poslední 3 měsíce</span>
                </li>
                <li>
                  ve <span className="font-bold">formátu PDF</span>
                </li>
              </ul>

              <p className="mb-9 text-center">
                Pokud používáte více účtů, nahrajte klidně všechny výpisy.
                Výsledek bude přesnější.
              </p>

              <FormGroup>
                <label
                  htmlFor="file-upload"
                  className="mb-4 inline-block text-center select-none tracking-widest transition-colors duration-200 ease-out focus:outline-none text-secondary bg-white hover:bg-secondary hover:text-white hover:shadow-button-secondary font-bold uppercase rounded-full py-3 px-4 sm:py-4 sm:px-6 text-base border-style border-3 border-secondary cursor-pointer"
                >
                  nahrát výpisy
                </label>
                <input
                  id="file-upload"
                  name="fileUpload"
                  type="file"
                  className="w-0 h-0 opacity-0"
                  accept="application/pdf"
                  onChange={handleUpload}
                  multiple
                  data-testid="file-upload"
                />
              </FormGroup>

              {data.map((file) => (
                <div
                  key={`${file.id}-${file.filename}`}
                  className="flex justify-between w-4/5 mt-4"
                  data-testid="uploaded-file"
                >
                  <span className="truncate">
                    <Pdf className="w-4 mr-3 inline" />
                    <span>{file.filename}</span>
                  </span>

                  <button
                    className="underline"
                    type="button"
                    onClick={() => deleteDocument(file.id)}
                  >
                    smazat
                  </button>
                </div>
              ))}
            </div>

            {error && error.isStatementsUnparseable && (
              <ErrorMessage
                className="pr-8 w-2/3"
                data-testid="manual-upload-error"
              >
                {error.humanFriendlyMessage}
                <a
                  className="underline cursor-pointer"
                  target="_blank"
                  rel="noopener noreferrer"
                  href="/podporovane-banky/"
                >
                  seznamu.
                </a>
              </ErrorMessage>
            )}

            {error &&
              (error.isStatementsEmpty ||
                error.isUnprocessableEntity ||
                error.isBriefHistoryOfStatements ||
                error.isLowNumberOfTransactions ||
                error.isMissingStatementInRow ||
                error.isOldStatements) && (
                <ErrorMessage
                  className="pr-8 w-2/3 text-center"
                  data-testid="manual-upload-error"
                >
                  {error.humanFriendlyMessage}
                </ErrorMessage>
              )}

            {isSubmitting && <Spinner size={64} className="mt-12 mx-auto" />}
            {!isSubmitting && (
              <FormGroup className="text-center">
                <Button
                  type="button"
                  onClick={handleSubmit}
                  border="normal"
                  disabled={!isValid}
                  className="mt-12"
                  data-testid="manual-upload"
                >
                  Pokračovat
                </Button>
              </FormGroup>
            )}
          </Form>
        )}
      </Formik>
    </Fragment>
  );
};
