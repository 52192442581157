import React from 'react';
import CountUp from 'react-countup';
import PropTypes from 'prop-types';

import Zero from '../../../images/score/0.png';
import Ten from '../../../images/score/10.png';
import Twenty from '../../../images/score/20.png';
import Thirty from '../../../images/score/30.png';
import Fourty from '../../../images/score/40.png';
import Fifty from '../../../images/score/50.png';
import Sixty from '../../../images/score/60.png';
import Seventy from '../../../images/score/70.png';
import Eighty from '../../../images/score/80.png';
import Ninety from '../../../images/score/90.png';
import Hundred from '../../../images/score/100.png';
import { formatInterestRate } from '.././../../utilities/formatNumbers';

export const Score = ({
  score = null,
  estimatedInterestRate,
  setShowScorePointsModal,
}) => {
  let image = Zero;

  if (score !== null) {
    const ensuredScore = Math.min(score, 1000); // ensure that maximum is 1000 points
    const temp = (100 * ensuredScore) / 1000;

    if (temp <= 0) {
      image = Zero;
    } else if (0 < temp && temp <= 10) {
      image = Ten;
    } else if (0 < temp && temp <= 20) {
      image = Twenty;
    } else if (20 < temp && temp <= 30) {
      image = Thirty;
    } else if (30 < temp && temp <= 40) {
      image = Fourty;
    } else if (40 < temp && temp <= 50) {
      image = Fifty;
    } else if (50 < temp && temp <= 60) {
      image = Sixty;
    } else if (60 < temp && temp <= 70) {
      image = Seventy;
    } else if (70 < temp && temp <= 80) {
      image = Eighty;
    } else if (80 < temp && temp <= 90) {
      image = Ninety;
    } else {
      image = Hundred;
    }
  }

  return (
    <div className="relative flex justify-center">
      <img
        src={image}
        className="animate-fade"
        alt="Speedmeter"
        aria-hidden="true"
      />
      <div className="absolute max-w-60 mx-auto bottom-0 text-center">
        <div
          className={`text-[28px] leading-8 md:text-4xl md:leading-[46px] ${
            score === null ? 'text-grey-3' : 'text-primary'
          } `}
          data-testid="your-score-value"
          data-score={score ?? 0}
        >
          Skóre <CountUp end={score ?? 0} />
        </div>
        <div
          className={`text-lg md:text-2xl font-extrabold leading-[26px] md:leading-8 ${
            score === null ? 'text-grey-4' : 'text-secondary'
          }`}
        >
          s odhadovaným úrokem{' '}
          <span className="whitespace-nowrap">
            {formatInterestRate(estimatedInterestRate ?? 0)} ročně
          </span>
        </div>
        <button
          className="md:mt-2 text-secondary underline leading-8"
          type="button"
          onClick={() => {
            setShowScorePointsModal(true);
          }}
          data-testid="show-score-points-modal-button"
        >
          Více o vašem úroku
        </button>
      </div>
    </div>
  );
};

Score.propTypes = {
  score: PropTypes.number,
  estimatedInterestRate: PropTypes.number,
};
