import React, { useContext, useEffect } from 'react';
import { useMedia } from 'react-use';
import { Router, useMatch } from '@reach/router';
import { navigate } from 'gatsby';
import { screens } from 'tailwindcss/defaultTheme';

import { Introduction } from './Introduction';
import { RegisterForm } from './RegisterForm';
import { AuthContext } from '../../../contexts/AuthContext';
import { Layout } from '../../../components/shared/Layout';
import { Seo } from '../../../components/shared/Seo';
import { ProgressBar } from '../../../components/shared/ProgressBar';

export const Insolvence = () => {
  const { user } = useContext(AuthContext);

  const isWide = useMedia(`(min-width: ${screens.lg})`);

  const matchIntro = useMatch('/moje/insolvence/');
  const matchForm = useMatch('/moje/insolvence/formular');

  useEffect(() => {
    if (user.personalNo) {
      navigate('/moje/nastenka?scrollTo=insolvence');
    }
  }, [user.personalNo]);

  const steps = [
    {
      key: '1',
      label: 'Úvodní informace',
      state: matchIntro ? 'in-progress' : 'pending',
    },
    {
      key: '2',
      label: 'Insolvenční rejstřík',
      state: matchForm ? 'in-progress' : 'pending',
    },
  ];

  return (
    <Layout
      asideContent={isWide ? <ProgressBar steps={steps} /> : null}
      logoVariant={isWide ? 'light' : 'dark'}
    >
      <Seo title="Insolvence" suppressRobots={true} />
      <Router>
        <Introduction path="/uvod" default />
        <RegisterForm path="/formular" />
      </Router>
    </Layout>
  );
};
