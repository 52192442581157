import React from 'react';
import PropTypes from 'prop-types';
import { Button } from '../Button';

export const ButtonContentWithIcon = ({
  onClick,
  url,
  label,
  iconSrc,
  disabled,
  color,
  size,
  className,
}) => {
  const handleClick = (event) => {
    if (disabled) {
      event.preventDefault();
      return;
    }

    onClick && onClick();
  };

  return (
    <Button
      border="normal"
      to={url}
      onClick={handleClick}
      disabled={disabled}
      data-testid="button-content-with-icon"
      color={color}
      size={size}
      className={className}
    >
      <span
        className="flex items-center"
        data-testid="button-content-with-icon-label"
      >
        {label && (
          <span className={`${size === 'small' ? 'mr-1' : 'mr-3'}`}>
            {label}
          </span>
        )}
        <img
          src={iconSrc}
          alt="Icon"
          aria-hidden="true"
          className={`w-auto ${size === 'small' ? 'h-3' : 'h-6'}`}
          data-testid="button-content-with-icon-image"
        />
      </span>
    </Button>
  );
};

ButtonContentWithIcon.defaultProps = {
  disabled: false,
};

ButtonContentWithIcon.propTypes = {
  url: PropTypes.string,
  label: PropTypes.string,
  iconSrc: PropTypes.string,
  disabled: PropTypes.bool,
};
