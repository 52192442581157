import React from 'react';
import PropTypes from 'prop-types';

import { Card } from '../../../../../components/shared/Card';
import { Recommendations } from './Recommendations';

export const ScoreHintCard = ({ recommendations }) => {
  return (
    <Card
      header={
        <span className="hidden md:inline" data-testid="score-hint-card-title">
          Co ovlivňuje vaše skóre?
        </span>
      }
    >
      <Recommendations recommendations={recommendations} />
    </Card>
  );
};

ScoreHintCard.propTypes = {
  recommendations: PropTypes.arrayOf(PropTypes.object),
};
