import React from 'react';
import PropTypes from 'prop-types';

import { FormGroupError } from '../FormGroupError';
import { FormInput } from '../FormInput';

export const FormPrimaryIncome = ({ onChange, touched, errors, autofocus }) => {
  return (
    <div className="border-grey-1 border-b pb-8">
      <span
        className="text-xl mb-8 block"
        data-testid="form-primary-income-question"
      >
        Jaký je váš celkový čistý příjem?
      </span>
      <FormInput
        type="number"
        title="Čistý měsíční příjem"
        name="primaryIncome"
        className="max-w-xs"
        placeholder="0 Kč"
        errors={errors}
        touched={touched}
        onChange={onChange}
        autofocus={autofocus}
        data-testid="form-primary-income-input"
      />
      <FormGroupError name="primaryIncome" />
    </div>
  );
};

FormPrimaryIncome.propTypes = {
  onChange: PropTypes.func.isRequired,
  touched: PropTypes.object,
  errors: PropTypes.object,
  autofocus: PropTypes.bool,
};
